import AjaxRequestHandler from '../helpers/ajax';

const { GLOBALS } = window;

export default class CheckLoggedIn {
    static doRequest() {
        AjaxRequestHandler.get(`${CLASSIC_SKYFISH_COM}/rest/identity`, (xhr, response) => {
            const { identity } = response;
            identity.csrf = GLOBALS.CSRF;
            AjaxRequestHandler.post('/p/user/auth', identity, (x, rs) => {
                if (rs.refresh) {
                    window.location.reload();
                }
            });
        });
    }
}
