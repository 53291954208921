import 'bootstrap.native';
import Masonry from 'masonry-layout';
import CheckLoggedIn from './components/base/check-logged-in';
import Dialog from './components/base/dialog-events';
import CampaignPopup from './components/base/campaign-popup';
import Statics from './components/helpers/statics';
import './components/helpers/events';
import './components/base/downloadAsZip';
import { isIOS, isAndroid } from './components/helpers/browserDetection';

import '../css/bootstrap/less/bootstrap.less';
import '../css/components.less';

const DialogEvents = new Dialog();
const CampaignPopupNewet = new CampaignPopup();

CampaignPopupNewet.showPopupAfterTwoSeconds();
DialogEvents.addEventListeners();
CheckLoggedIn.doRequest();

/* Keydowns on previewpage */
function triggerClicks(e) {
    let activeButton;

    if (e.keyCode === 37) { // left
        activeButton = document.getElementById('js-previousUrl');
    } else if (e.keyCode === 39) { // right
        activeButton = document.getElementById('js-nextUrl');
    } else if (e.keyCode === 27) { // escape
        if (document.getElementsByClassName('modal-backdrop').length === 0) {
            activeButton = document.getElementById('js-closeButton');
        }
    }

    if (activeButton !== undefined && activeButton !== null) {
        const url = activeButton.getAttribute('href')
        setTimeout(() => {
            window.location.href = url;
        }, 1);
    }
}

document.getElementsByTagName('body')[0].addEventListener('keydown', triggerClicks);


/* Static nav bar */
const $navbar = document.getElementsByClassName('row--navigationBar')[0];
const $navbarContainer = document.getElementsByClassName('container--navigationBar')[0];
const $customHeader = document.getElementsByClassName('jumbotron--customHeader')[0];

const classNames = ['navbar', 'navbar-default', 'navbar-fixed-top', 'navbar--custom'];
let alreadyAddedClasses = false;
function setStaticNavbarIfNeeded() {
    const headerHeight = document.getElementsByClassName('jumbotron--customHeader')[0].getBoundingClientRect().height;

    const minimumPos = headerHeight - $navbar.getBoundingClientRect().height;
    const currentScrollPos = document.documentElement.scrollTop === 0 ? document.body.scrollTop : document.documentElement.scrollTop;

    if (currentScrollPos > minimumPos) {
        if (!alreadyAddedClasses) {
            Statics.addClasses($navbarContainer, ['container--navigationBar-fixed']);
            Statics.addClasses($navbar, classNames);
            alreadyAddedClasses = true;
        }
    } else if (alreadyAddedClasses) {
        Statics.removeClasses($navbarContainer, ['container--navigationBar-fixed']);
        Statics.removeClasses($navbar, classNames);
        alreadyAddedClasses = false;
    }
}

if ($navbar !== undefined && $customHeader !== undefined) {
    window.addEventListener('scroll', setStaticNavbarIfNeeded);
}

function toggleSearchBar() {
    Statics.toggleClasses('hidden-xs');
    const searchBar = document.querySelector('.input-group--search input[type="text"]');
    const mobileSearch = document.getElementsByClassName('js--glyphicon-search--mobile')[0];
    searchBar.removeEventListener('blur', toggleSearchBar);
    if (mobileSearch.hasClass('hidden-xs')) {
        searchBar.focus();
        searchBar.addEventListener('blur', toggleSearchBar);
    }
}

/* Masonry related images */

function loopRelatedImages() {
    window.loadedImagesCount++;
    if (window.loadedImagesCount >= window.imageNames.length) {
        if (window.gridDOM && window.gridDOM.hasClass('relatedImages-grid--hidden')) {
            window.gridDOM.classList.remove('relatedImages-grid--hidden');
        }

        window.msnry = new Masonry(window.gridDOM, {
            itemSelector: '.relatedImages-grid-item',
        });
    }
}

window.gridDOM = document.getElementById('relatedGrid');
if (window.gridDOM) {
    window.gridImages = window.gridDOM.querySelectorAll('img');
    window.loadedImagesCount = 0;
    window.imageNames = [];
    window.imagesArray = [];

    for (let i = window.gridImages.length - 1; i >= 0; i--) {
        window.imageNames.push(window.gridImages[i].src);
    }

    for (let i = 0; i < window.imageNames.length; i++) {
        const image = new Image();
        image.src = window.imageNames[i];
        image.onload = loopRelatedImages;
        window.imagesArray.push(image);
    }
}

function togglePreviewPdf(url, mediaId) {
    const pdfPreviewWrapper = document.getElementById('pdf-preview');
    const pdfPreviewElement = document.getElementById('pdf-' + mediaId);

    if (pdfPreviewElement) {
        pdfPreviewWrapper.removeChild(pdfPreviewElement);
        return;
    }

    if (isIOS() || isAndroid()) {
        const iframeElement = document.createElement('iframe');
        iframeElement.id = 'pdf-' + mediaId;
        iframeElement.src = 'https://docs.google.com/gview?url=' + url + '&embedded=true';
        pdfPreviewWrapper.appendChild(iframeElement);
    } else {
        const objectElement = document.createElement('object');
        const embedElement = document.createElement('embed');
        objectElement.id = 'pdf-' + mediaId;
        embedElement.src = url;
        objectElement.data = url;
        objectElement.type = 'application/pdf';
        embedElement.type = 'application/pdf';
        objectElement.appendChild(embedElement);
        pdfPreviewWrapper.appendChild(objectElement);
    }
}

// Extend native Element //
Element.prototype.hasClass = function (className) { // eslint-disable-line func-names
    if (this && this.classList) {
        return this.classList.contains(className);
    }
    return new RegExp('(^| )' + className + '( |$)', 'gi').test(this.className);
};

window.togglePreviewPdf = togglePreviewPdf;
window.toggleSearchBar = toggleSearchBar;
