export default class AjaxRequestHandler {
    static xhr() {
        if (window.XMLHttpRequest) {
            return new XMLHttpRequest();
        }
        try {
            return new window.ActiveXObject('Microsoft.XMLHTTP');
        } catch (e) {
            return null;
        }
    }

    static get(link, successCallback, errorCallback) {
        this.doRequest(link, null, successCallback, errorCallback, 'GET');
    }

    static post(link, data, successCallback, errorCallback) {
        this.doRequest(link, data, successCallback, errorCallback, 'POST');
    }

    static put(link, data, successCallback, errorCallback) {
        this.doRequest(link, data, successCallback, errorCallback, 'PUT');
    }

    static delete(link, data, successCallback, errorCallback) {
        this.doRequest(link, data, successCallback, errorCallback, 'DELETE');
    }

    static doRequest(link, data, successCallback, errorCallback, type) {
        const xhr = this.xhr();
        xhr.withCredentials = true;
        xhr.open(type, link, true);
        xhr.onreadystatechange = function () { // eslint-disable-line func-names
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    const responseJSON = JSON.parse(xhr.responseText);
                    successCallback(xhr, responseJSON);
                } else if (typeof errorCallback === 'function') {
                    errorCallback(xhr);
                }
            }
        };

        xhr.send(JSON.stringify(data));
    }
}
