export function isAndroid() {
    return /Android/.test(navigator.userAgent);
}

export function isIOS() {
    return /iP(hone|od|ad)/.test(navigator.userAgent);
}

export function isIE() {
    const myNav = navigator.userAgent.toLowerCase();
    return (myNav.indexOf('msie') !== -1) ? parseInt(myNav.split('msie')[1], 10) : false;
}
