import { Modal } from 'bootstrap.native';
import Statics from '../helpers/statics';
import Notification from '../helpers/Notification';

export default class DialogEvents {
    constructor() {
        this.openDialog = this.openDialog.bind(this);
        this.setupDownloadNoteDialogTemplate = this.setupDownloadNoteDialogTemplate.bind(this);
        this.handleDownloadNotes = this.handleDownloadNotes.bind(this);
    }

    handleDownloadNotes(currentHref) {
      const downloadNoteHref = currentHref && currentHref.replace(/download\/[a-zA-Z0-9]*$/i, 'download/note');
      fetch(downloadNoteHref)
        .then(response => response.json())
        .then((data) => {
          if (data.length < 1) {
            window.location.href = currentHref;
          } else {
            this.openDialog(this.setupDownloadNoteDialogTemplate(currentHref, data));
          }
        })
        .catch(() => {
          Notification.setLifeTime(3000).show('error', 'Failed to fetch download notes for media');
          window.location.href = currentHref;
        });
    }

    setupDownloadNoteDialogTemplate(currentHref, notes) {
        const template = document.getElementById('dialogDownloadNote');
        const noteContainer = template.getElementsByClassName('dialog-downloadnote--container');

        while (noteContainer[0].firstChild) {
            noteContainer[0].removeChild(noteContainer[0].firstChild);
        }

        for (let i = 0; i < notes.length; i++) {
            const divElementForSpan = document.createElement('DIV');
            Statics.addClasses(divElementForSpan, ['col-xs-2']);

            const spanElement = document.createElement('SPAN');
            Statics.addClasses(spanElement, ['glyphicon', 'glyphicon-exclamation-sign', 'u-textColour', 'dialog-downloadnote-span']);
            divElementForSpan.appendChild(spanElement);

            const divElementForP = document.createElement('DIV');
            Statics.addClasses(divElementForP, ['col-xs-18']);

            const pElement = document.createElement('P');
            Statics.addClasses(pElement, ['dialog-downloadnote-text']);

            const text = document.createTextNode(notes[i].note);
            pElement.appendChild(text);
            divElementForP.appendChild(pElement);

            noteContainer[0].appendChild(divElementForSpan);
            noteContainer[0].appendChild(divElementForP);
        }

        const downloadButton = template.getElementsByClassName('dialog-downloadnote--download');

        downloadButton[0].setAttribute('href', currentHref);
        downloadButton[0].addEventListener('click', () => {
            window.location.href = currentHref;
        });

        return template;
    }

    addEventListeners() {
        const dropdownMenuLink = document.getElementsByClassName('dropdown-menu-link');
        const directDownloadLink = document.getElementById('image-download-button');

        if (directDownloadLink !== undefined && directDownloadLink !== null) {
            directDownloadLink.addEventListener('click', this.downloadButtonDownload.bind(this), false);
        }

        if (dropdownMenuLink !== undefined && dropdownMenuLink !== null) {
            for (let i = 0; i < dropdownMenuLink.length; i++) {
                dropdownMenuLink[i].addEventListener('click', this.dropDownloadMenuClick.bind(this), false);
            }
        }
    }

    downloadButtonDownload(e) {
        e.preventDefault();
        // button is inside a tag, so get a tags href.
        const currentHref = e.target.parentElement.href;
        this.handleDownloadNotes(currentHref);
    }

    openDialog(template) {
        const modalDialog = new Modal(template, { backdrop: true });
        modalDialog.show();
    }

    dropDownloadMenuClick(e) {
        e.preventDefault();
        const currentHref = typeof e.target.href === 'undefined' ? e.target.parentElement.href : e.target.href;
        this.handleDownloadNotes(currentHref);
    }
}
