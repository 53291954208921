const { _translations } = window;

export default class Translation {
    constructor() {
        this.translations = _translations;
    }

    trans(key, placeholderObject) {
        const splitKey = Translation.splitKeyIntoFractions(key);
        let translation = this.translations;

        // eslint-disable-next-line
        for (const i in splitKey) {
            if (typeof translation === 'undefined') {
                break;
            }
            translation = translation[splitKey[i]];
        }

        if (typeof translation !== 'string') {
            return key;
        }

        translation = Translation.replacePlaceHolders(translation, placeholderObject);

        return translation;
    }

    static splitKeyIntoFractions(key) {
        return key.split('.');
    }

    static replacePlaceHolders(text, placeholderObject) {
        let returnText = text;
        if (typeof placeholderObject !== 'object') {
            return returnText;
        }

        // eslint-disable-next-line
        for (const i in placeholderObject) {
            returnText = returnText.replace(`:${i}`, placeholderObject[i]);
        }

        return returnText;
    }
}
