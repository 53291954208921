import { Modal } from 'bootstrap.native';
import { domReady } from '../helpers/events';
import AjaxRequestHandler from '../helpers/ajax';
import Notification from '../helpers/Notification';
import Translation from '../helpers/translation';

const translation = new Translation();
let modal;

domReady(() => {
    const downloadButtons = [...document.getElementsByClassName('js--downloadFolderAsZip')];

    for (const downloadButton of downloadButtons) {
        downloadButton.addEventListener('click', onZipDownloadRequested);
    }

    const dialog = document.getElementById('downloadZipDialog');

    if (dialog) {
        modal = new Modal(dialog, { backdrop: true });
    } else {
        console.log('Warning: downloadZipDialog element not found.');
        return;
    }

    const button = document.getElementById('js--dialogDownloadFolderAsZip');

    if (button) {
        button.addEventListener('click', closeModal);
    } else {
        console.log('Warning: js--dialogDownloadFolderAsZip element not found.');
    }
});

function onZipDownloadRequested(e) {
    e.preventDefault();

    const href = e.target?.href;

    if (!href) {
        console.log('Warning: href attribute is missing from the clicked element.');
        return;
    }

    AjaxRequestHandler.get(href, (data) => {
        const { mediaCount, zip, statusUrl } = JSON.parse(data.response);

        // zip.state = 'PENDING'; // uncomment to test loading state

        modal.show();

        const dialogTitle = mediaCount > 1
            ? translation.translations.public.download.download_x_files
            : translation.translations.public.download.download_single_file;

        const titleElement = document.getElementById('js--dialogDownloadFolderAsZip-title');
        if (titleElement) {
            titleElement.innerHTML = dialogTitle.replace('%s', mediaCount);
        } else {
            console.log('Warning: js--dialogDownloadFolderAsZip-title element not found.');
        }

        updateLoadingState(zip, statusUrl);
    }, (error) => {
        const response = JSON.parse(error.response);

        if (response.key === 'media_count_limit') {
            Notification.setLifeTime(4000).show('error', `Sorry, folder you are trying to download has more than ${response.limit} files.`);
        }
    });
}

function updateLoadingState(zip, statusUrl) {
    const button = document.getElementById('js--dialogDownloadFolderAsZip');
    const spinner = document.getElementById('js--dialogDownloadFolderAsZip-spinner');

    if (!button || !spinner) {
        console.log('Warning: Loading state elements not found.');
        return;
    }

    const isLoading = zip.state === 'PENDING';

    button.toggleAttribute('disabled', isLoading);
    spinner.style.display = isLoading ? 'inline-block' : 'none';

    if (isLoading) {
        setTimeout(() => {
            AjaxRequestHandler.get(
                statusUrl,
                data => updateLoadingState(JSON.parse(data.response).zip, statusUrl),
            );
        }, 2000);
    } else if (zip.url) {
        button.href = zip.url;
    } else {
        console.log('Warning: zip URL is not defined.');
    }
}

function closeModal() {
    if (modal) {
        modal.hide();
    } else {
        console.log('Warning: Modal instance not initialized.');
    }
}
