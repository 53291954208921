import Statics from '../helpers/statics';

export default class CampaignPopup {
    constructor() {
        this.popupMin = document.getElementsByClassName('js-popup--view-minimized')[0]; // eslint-disable-line prefer-destructuring
        this.popupMax = document.getElementsByClassName('js-popup--view-maximized')[0]; // eslint-disable-line prefer-destructuring
        this.popupCloseButton = document.getElementsByClassName('js-popup-close')[0]; // eslint-disable-line prefer-destructuring
        this.togglePopupOnClick();
    }

    openPopup() {
        Statics.addClasses(this.popupMin, ['popup--state-isHidden']);
        Statics.removeClasses(this.popupMin, ['popup--state-isVisible']);

        Statics.addClasses(this.popupMax, ['popup--state-isVisible']);
        Statics.removeClasses(this.popupMax, ['popup--state-isHidden']);
    }

    closePopup() {
        Statics.addClasses(this.popupMax, ['popup--state-isHidden']);
        Statics.removeClasses(this.popupMax, ['popup--state-isVisible']);

        Statics.addClasses(this.popupMin, ['popup--state-isVisible']);
        Statics.removeClasses(this.popupMin, ['popup--state-isHidden']);
    }

    togglePopupOnClick() {
        if (typeof this.popupMin === 'undefined' || typeof this.popupMax === 'undefined') {
            return;
        }

        this.popupMin.onclick = () => this.openPopup();
        this.popupCloseButton.onclick = () => this.closePopup();
    }

    showPopupAfterTwoSeconds() {
        if (typeof this.popupMin === 'undefined' || typeof this.popupMax === 'undefined') {
            return;
        }

        setTimeout(this.openPopup.bind(this), 2000);
    }
}
