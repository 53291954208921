class Notification {
    constructor() {
        this.leaveAnimationTimeMs = 200;
        this.defaultLifetimeMs = 10000;
        this.notificationType = null;
        this.notificationMessage = null;
        this.notificationLifetimeTimeout = null;
        this.notificationShowTimeout = null;
        this.hideTimout = null;
        this.notificationSelector = null;
    }

    show(type, message) {
        this.notificationType = type;
        this.notificationMessage = message;

        if (this.notificationSelector !== null) {
            this._removeNotificationFromDom();
        }
        this._createNotification();
    }

    setLifeTime(ms) {
        this.defaultLifetimeMs = ms;
        return this;
    }

    hide() {
        this._removeNotificationFromDom();
    }

    _createNotification() {
        const notificationWrapper = document.createElement('div'),
            notificationBackground = document.createElement('div'),
            notificationText = document.createElement('div');

        notificationWrapper.setAttribute('id', 'notification');
        notificationWrapper.className = `notification ${this.notificationType} ${this.notificationType === 'success' ? 'u-borderColour u-textColour--variation' : ''}`;

        notificationBackground.className = `notification-background ${this.notificationType === 'success' ? 'u-bgColour' : ''}`;
        notificationWrapper.appendChild(notificationBackground);

        notificationText.className = 'notification-text';
        notificationText.innerHTML = this.notificationMessage;
        notificationWrapper.appendChild(notificationText);

        document.body.appendChild(notificationWrapper);

        this.notificationShowTimeout = setTimeout(() => {
            notificationWrapper.className += ' visible';
        }, 1);

        this.notificationSelector = document.getElementById('notification');

        this.notificationLifetimeTimeout = setTimeout(() => {
            this._hideNotification();
        }, this.defaultLifetimeMs + this.leaveAnimationTimeMs);
    }

    _removeNotificationFromDom() {
        if (this.notificationSelector !== null) {
            this.notificationSelector.parentNode.removeChild(this.notificationSelector);
            this._resetNotificationTimeouts();
            this.notificationSelector = null;
        }
    }

    _hideNotification() {
        if (this.notificationLifetimeTimeout !== null) {
            this.notificationSelector.className = `notification ${this.notificationType} ${this.notificationType === 'success' ? 'u-borderColour u-textColour--variation' : ''}`;
            this.hideTimout = setTimeout(() => {
                this._removeNotificationFromDom();
            }, this.leaveAnimationTimeMs);
        }
    }

    _resetNotificationTimeouts() {
        clearTimeout(this.notificationLifetimeTimeout);
        clearTimeout(this.notificationShowTimeout);
        clearTimeout(this.hideTimeout);
    }
}
export default new Notification();
