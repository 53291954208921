export default class Statics {
    static addClasses(element, classes) {
        if (element) {
            for (let i = classes.length - 1; i >= 0; i--) {
                if (element.classList) {
                    element.classList.add(classes[i]);
                } else {
                    element.className += ' ' + classes[i]; // eslint-disable-line no-param-reassign
                }
            }
        }
    }

    static removeClasses(element, classes) {
        if (element) {
            for (let i = classes.length - 1; i >= 0; i--) {
                if (element.classList) {
                    element.classList.remove(classes[i]);
                } else {
                    element.className = element.className.replace(new RegExp('(^|\\b)' + classes[i].split(' ').join('|') + '(\\b|$)', 'gi'), ' '); // eslint-disable-line no-param-reassign
                }
            }
        }
    }

    static toggleClasses(className) {
        const elements = document.getElementsByClassName('js--' + className);
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].hasClass(className)) {
                Statics.removeClasses(elements[i], [className]);
            } else {
                Statics.addClasses(elements[i], [className]);
            }
        }
    }
}
